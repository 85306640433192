export const homeObjOne1 = {
  lightBg: false,
  primary: true,
  imgStart: "",
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  buttonLabel: "Get Started",
  topLine: "Nandini K Fashion",
  headline: "Your Style, Designed Around You",
  description:
    "We bring your fashion dreams to life with precision and style. Specializing in custom tailoring, alterations, and bespoke stitching for all types of clothing, we ensure every garment fits perfectly and reflects your unique designed.",
  start: "",
  img: require("../Pages/images/svg-1.svg").default,
  alt: "Image",
};
export const homeObjOne2 = {
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  buttonLabel: "Get Started",
  topLine: "Nandini K Fashion",
  headline: "Crafting Your Perfect Fit, Uniquely You",
  description:
    "Our skilled artisans pay attention to every detail, whether it’s creating beautiful ethnic wear, perfectly fitted formal attire, or enhancing your favorite outfits with expert alterations.",
  start: "true",
  img: require("../Pages/images/profile.jpg"),
  alt: "Image",
};
export const homeObjOne3 = {
  lightBg: false,
  primary: true,
  imgStart: "",
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  buttonLabel: "Get Started",
  topLine: "Nandini K Fashion",
  headline: "Refined Fashion, Tailored to Your Vision",
  description:
    "Visit NK Fashion for a personalized, stylish, and comfortable stitching experience—where quality meets creativity!",
  start: "",
  img: require("../Pages/images/svg-2.svg").default,
  alt: "Image",
};

export const homeObjOne4 = {
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  buttonLabel: "Get Started",
  topLine: "Nandini Fashion",
  headline: "Personalized Perfection in Every Dress",
  description:
    "We’re committed to quality, customization, and a personal touch in every piece, making each dress more than just an outfit.",
  start: "true",
  img: require("../Pages/images/svg-3.svg").default,
  alt: "Image",
};
